<template>
  <div class="card shadow mb-4">
    <div class="card-header font-weight-bold text-dark">
      <span v-html="title"></span>
      <a href="#" @click.stop.prevent="triggerUpload" class="float-right font-weight-light">Upload</a>
    </div>
    <div class="card-body text-center">
      <img :src="actualImage" class="img-thumbnail">
      <p class="text-center m-0">
        <a href="#" v-show="withImage" @click.stop.prevent="removeImage" class="text-danger">Delete</a>  
      </p>
      <input ref="uploadInput" type="file" class="d-none" @change="onFileChange" :name="inputName">
      <input type="hidden" :name="removeFieldName" v-model.number="isRemoving">
      <input type="hidden" :name="unchangedFieldName" v-model.number="isUnchanged">
    </div>
  </div>
</template>

<style>
#remove-img-container { position: absolute; bottom: 10px; }
</style>

<script>
  export default {
    data() {
      return {
        isUnchanged: 1,
        isRemoving: 0,
        image: null,
      }
    },
    props: {
      title: { default: 'Product Thumbnail' },
      default: { default: null },
      existing: { default: null },
      inputName: { default: 'thumbnail' },
    },
    computed: {
      actualImage() {
        return this.image ? this.image : this.default
      },
      withImage() {
        return this.image ? true : false
      },
      removeFieldName() {
        return this.inputName ? this.inputName + '_removing' : 'removing'
      },
      unchangedFieldName() {
        return this.inputName ? this.inputName + '_unchanged' : 'unchanged'
      }
    },
    methods: {
      triggerUpload() {
        this.$refs.uploadInput.click()
      },
      onFileChange(e) {
        this.isUnchanged = 0
        this.isRemoving = 0
        let files = e.target.files || e.dataTransfer.files
        if (!files.length) return

        this.createImage(files[0])
      },
      createImage(file) {
        let image = new Image()
        let reader = new FileReader()
        let that = this

        reader.onload = (e) => {
          that.image = e.target.result
        }

        reader.readAsDataURL(file)
      },
      removeImage(e) {
        this.isUnchanged = 0
        this.isRemoving = 1
        this.image = null
        this.$refs.uploadInput.value = null
      }
    },
    mounted() {
      if (this.existing) {
        this.image = this.existing + '?' + new Date().getTime()
      }
    }
  }
</script>