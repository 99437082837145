import axios from 'axios'
import _ from 'lodash'

// initial state
const state = () => ({
  all: [],
  states: [],
})

// getters
const getters = {
  getSettingByKey: (state) => (key) => {
    return state.all.find(setting => setting.name == key)
  },
}

// actions
const actions = {
  getAllSettings ({ commit }) {
    axios.get('/api/v1/setting').then(resp => {
      commit('setSettings', resp.data)
    })
  },

  getStatesWithFees ({ commit }) {
    return new Promise((resolve, reject) => {
        axios.get('/api/v1/setting/shipping').then(resp => {
            commit('setShippingStates', resp.data)
            resolve(resp)
        }, err => {
            reject(err)
        })
    })
  },
}

// mutations
const mutations = {
  setSettings (state, settings) {
    state.all = settings
  },
  setShippingStates (state, states) {
    state.states = states
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}