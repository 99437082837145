<template>
  <canvas id="category-pie-chart"></canvas>
</template>

<script>
  export default {
    props: ['data'],
    data() {
      return {
        colors: ['#F94144', '#F3722C', '#F8961E', '#F9C74F', '#90BE6D', '#43AA8B', '#577590', '#edddd4', '#277da1', '#b392ac', '#f19c79'],
        categories: [],
        config: {
          type: 'pie',
          data: {
            labels: [],
            datasets: [{
              data: [],
              backgroundColor: [],
            }],
          },
          options: {
            legend: { position: 'bottom', },
            aspectRatio: 1
          }
        }
      }
    },
    mounted () {
      this.categories = JSON.parse(this.data)
      this.categories.forEach((category, index) => {
        this.config.data.labels.push(category.name)
        this.config.data.datasets[0].data.push(category.products_count)
        this.config.data.datasets[0].backgroundColor.push(this.colors[Math.floor(Math.random() * this.colors.length)])

      })

      this.$parent.createChart('category-pie-chart', this.config);
    }
  }  
</script>
