<template>
  <tr>
    <td class="align-middle text-center">
      <img :src="product.thumbnail ? product.thumbnail : '/images/product-default.png'" :alt="product.title" class="img-xs rounded">
    </td>
    <td width="50%" class="px-1 align-middle">
      <p class="font-weight-bold mb-0">{{ product.title }}</p>
      <div class="input-group input-group-sm mt-2">
        <div class="input-group-prepend">
          <button class="btn btn-light btn-deduct-5" type="button" @click="decreaseProductQuantity({ product, qty:5 })">
            -5
          </button>
          <button class="btn btn-light btn-deduct" type="button" @click="decreaseProductQuantity({ product, qty })">
            -
          </button>
        </div>
        <input type="number" class="form-control text-center" name="qty[]" readonly v-model="product.quantity">
        <div class="input-group-append">
          <button class="btn btn-light btn-increase" type="button" @click="addProductToCart({ product, qty })">
            +
          </button>
          <button class="btn btn-light btn-increase-5" type="button" @click="addProductToCart({ product, qty:5 })">
            +5
          </button>
        </div>
      </div>
    </td>
    <td width="35%" class="text-right align-middle">
      <p class="mb-0 price">{{ itemTotal(product.id) | currency }}</p>
      <p class="small text-muted mb-0">
        {{ product.price | currency }} <span v-if="product.unit" class="small">/ {{ product.unit }}</span>
      </p>
      <button :id="removeBtnId" class="btn btn-basic btn-sm mt-2 text-danger" type="button">
        <i class="fa fa-times-circle"></i> {{ $t('Remove') }}
      </button>
      <b-popover
        :target="removeBtnId"
        triggers="click"
        :show.sync="showPopover"
        placement="auto"
        ref="popover"
        variant="danger"
      >
        {{ $t('removeCartItemMsg') }}
        <div class="mt-2">
          <b-button @click="removeProductFromCart(product)" size="sm" variant="danger">{{ $t('Confirm remove') }}</b-button>  
          <b-button @click="showPopover = false" size="sm" variant="secondary">{{ $t('Cancel') }}</b-button>  
        </div>
      </b-popover>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['product'],
  data() {
    return {
      qty: 1,
      showPopover: false,
    }
  },
  computed: {
    removeBtnId() {
      return `rm-btn-${this.product.id}`
    },
    hasItemInCart() {
      return this.count > 0
    },
    ...mapGetters({ 
      itemTotal: 'cart/cartSingleItemTotalPrice'
    }),
  },
  methods: {
    ...mapActions('cart', [
      'addProductToCart',
      'removeProductFromCart',
      'decreaseProductQuantity'
    ]),
  },
}
</script>