<template>
  <section class="section-content padding-y">
    <div class="container pt-3">
      <div class="row">
        <main class="col-md-9 mx-auto">
          <div class="card p-4 shadow">
            <div class="card-body">
              <h1 class="m-0 mb-1 text-center">
                <i class="fa fa-search fa-2x mb-2 d-block"></i>
                {{ $t('Order Tracking') }}
              </h1>
              <form ref="searchOrderForm" class="form-inline pt-5 justify-content-center" @submit.prevent="submitForm">
                <div class="form-group mb-0 mr-2">
                  <label for="orderCodeInput" class="sr-only">{{ $t('Order code') }}</label>
                  <input type="text" class="form-control form-control-lg" id="orderCodeInput" :placeholder="$t('Order code')" v-model="orderCode">
                </div>
                <b-button type="submit" variant="primary" size="lg" :disabled="!isLoading && !orderCode">
                  <template v-if="isLoading">
                    <b-spinner v-if="isLoading" small style="margin-bottom: 4px;" class="mr-2"></b-spinner>
                    {{ $t('Searching') }}...
                  </template>
                  <span v-else>{{ $t('Search') }}</span>
                </b-button>
              </form>
              <p v-if="errorMsg" class="text-center text-danger small mt-2">{{ errorMsg }}</p>
              <p class="text-center text-muted small px-2 mt-4">
                {{ $t('trackOrderMsg') }}
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'order-tracking-page',
    data() {
      return {
        isLoading: false,
        orderCode: '',
        errorMsg: '',
      }
    },
    methods: {
      submitForm() {
        this.errorMsg = ''
        this.isLoading = true

        axios.post('/api/v1/order/get', {
          'order_code': this.orderCode
        }).then(resp => {
          if (resp.data.order) {
            let order = resp.data.order
            this.$router.push({ name: 'order-detail', params: { code: order.code, order: order } })
          } else {
            this.errorMsg = '出现错误，请刷新重试。如问题持续，请联络我们的客服'
          }
          
        }).catch(err => {
          if (err.response && err.response.status == 404) { 
            this.errorMsg = err.response.data.message
          }
        })

        this.isLoading = false
      },
    }
  }
</script>