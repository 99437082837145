<template>
  <b-button variant="success" size="sm" v-b-modal.receiptApproveModal @click="setReceiptInfo">
    <i class="fas fa-check-circle mr-1"></i> Approve
  </b-button>
</template>

<script>
  export default {
    props: ['route', 'withEmail', 'withFb'],
    methods: {
      setReceiptInfo() {
        this.$parent.receipt.route = this.route
        this.$parent.receipt.withEmail = this.withEmail
        this.$parent.receipt.withFb = this.withFb
      }
    },
    mounted() {
      console.log(this.withEmail)
      console.log(this.withFb)
    }
  }
</script>