<template>
	<footer class="section-footer border-top bg-light">
		<div class="container">
			<section class="footer-copyright">
					<p class="text-center d-flex justify-content-center align-items-center">
						<router-link :to="$i18nRoute({ name: 'terms' })" class="brand-wrap">{{ $t('Terms of Use') }}</router-link>
						<i class="text-muted mx-2 fa fa-circle" style="font-size: 3px;"></i>
						<router-link :to="$i18nRoute({ name: 'privacy' })" class="brand-wrap">{{ $t('Privacy Policy') }}</router-link>
						<i class="text-muted mx-2 fa fa-circle" style="font-size: 3px;"></i>
						<router-link :to="$i18nRoute({ name: 'order' })" class="brand-wrap">{{ $t('Track Order') }}</router-link>
						<i class="text-muted mx-2 fa fa-circle" style="font-size: 3px;"></i>
						<a href="http://layangfood.com" target="_blank">{{ $t('Visit Official Site') }}</a>
					</p>
					<p class="text-center">
						<a :href="clickToChatURL" target="_blank" class="btn text-light" style="background-color: #25d366;">
							<i class="fab fa-lg fa-whatsapp mr-2"></i> {{ $t('Contact us') }} +{{ whatsappNo }}
						</a>
					</p>
					<p class="text-center mb-0">&#169;{{ currentYear }} Layang Food Sdn Bhd (937111-H)</p>
					<p class="text-center text-muted">
						<small>
							<a href="https://www.koderpillar.com" target="_blank">Made with ❤️ by Koderpillar</a>
						</small>
					</p>
			</section>
		</div>
	</footer>
</template>

<script>
	import { mapGetters } from 'vuex'
	
	export default {
		computed: {
			whatsappNo() {
				return this.getSetting('whatsapp_no') ? this.getSetting('whatsapp_no').value : null
			},
			clickToChatURL() {
				return `https://wa.me/${this.whatsappNo}`
			},
			currentYear() {
				return new Date().getFullYear()
			},
			...mapGetters({ 
        getSetting: 'settings/getSettingByKey',
      }),
		},
		created() {
			this.$store.dispatch('settings/getAllSettings')
		}
	}
</script>