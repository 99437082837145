
import Vue from 'vue'
import Vuex from 'vuex'

import cart from './modules/cart'
import products from './modules/products'
import settings from './modules/settings'
import createPersistedState from "vuex-persistedstate"

import axios from 'axios'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: true,
  modules: {
    cart,
    products,
    settings,
  },
  plugins: [
    createPersistedState({
      key: 'lf',
    })
  ]
})

export default store