<template>
  <transition name="back-to-top-fade">
    <div
      id="back-to-top"
      class="btn btn-primary text-center px-2 y-1 shadow"
      @click="backToTop"
      v-show="visible"
      :style="`bottom:${this.bottom};right:${this.right};`"
    >
      <i class="fa fa-angle-double-up mb-1 fa-2x"></i><br>
      <small class="d-block" style="font-size: 55%;">{{ $t('Back to top') }}</small>
    </div>
  </transition>
</template>

<style>
  #back-to-top {
    position: fixed;
    z-index: 1000;
  }
  .back-to-top-fade-enter-active, 
  .back-to-top-fade-leave-active {
    transition: opacity .7s;
  }
  .back-to-top-fade-enter, 
  .back-to-top-fade-leave-to {
    opacity: 0;
  }
</style>

<script>
export default {
  name: 'back-to-top',
  props: {
    text: {
      type: String,
      default: 'Voltar ao topo',
    },
    visibleoffset: {
      type: [String, Number],
      default: 600,
    },
    visibleoffsetbottom: {
      type: [String, Number],
      default: 0,
    },
    right: {
      type: String,
      default: '15px',
    },
    bottom: {
      type: String,
      default: '70px',
    },
  },
  data () {
    return {
      visible: false
    }
  },
  mounted () {
    window.smoothscroll = () => {
      let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.requestAnimationFrame(window.smoothscroll)
        window.scrollTo(0, Math.floor(currentScroll - (currentScroll / 5)))
      }
    }
    window.addEventListener('scroll', this.catchScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.catchScroll)
  },
  methods: {
    /**
     * Catch window scroll event 
     * @return {void}
     */
    catchScroll () {
      const pastTopOffset = window.pageYOffset > parseInt(this.visibleoffset)
      const pastBottomOffset = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - parseInt(this.visibleoffsetbottom)
      this.visible = parseInt(this.visibleoffsetbottom) > 0 ? pastTopOffset && !pastBottomOffset : pastTopOffset
    },
    /**
     * The function who make the magics
     * @return {void}
     */
    backToTop () {
      window.smoothscroll()
    }
  },
}
</script>