<template>
  <div class="card shadow mb-4">
    <div class="card-header font-weight-bold text-dark">Product Gallery</div>
    <div class="card-body">
      <draggable 
        v-model="imageBoxes"
        ghost-class="ghost"
        @start="isDragging=true"
        @end="isDragging=false"
        class="draggable-cont"
        handle=".drag-handle">
        <transition-group type="transition" tag="div" class="row">
          <div v-for="(box, index) in imageBoxes" class="draggable-box col-3 col-sm-3 p-1" :key="box.id">
            <input :ref="'uploadInput-' + box.id" type="file" class="d-none" @change="onFileChange($event, box)" :name="`images[${index}][img]`">
            <input type="hidden" :name="`images[${index}][img_id]`" v-model="box.imgId">
            <input type="hidden" :name="`images[${index}][removing]`" v-model="box.isRemoving">
            <a v-if="box.existing" href="#" @click.prevent="showImage(box.existing)" class="d-flex justify-content-center border box overflow-hidden">
              <img :src="box.existing" height="100%">
            </a>
            <a v-else-if="box.img" href="#" @click.prevent="showImage(box.img)" class="d-flex justify-content-center border box overflow-hidden">
              <img :src="box.img" height="100%">
            </a>
            <a v-else href="#" @click.prevent="triggerUpload(box)" class="d-flex align-items-center justify-content-center border box">
              <i class="fas fa-upload"></i>
            </a>
            <a v-show="box.img || box.existing" class="btn btn-info btn-sm p-0 drag-handle">
              <i class="fas fa-arrows-alt text-light d-flex p-1"></i>
            </a>
            <a v-show="box.img || box.existing" class="btn btn-danger btn-sm p-0 remove-box-btn" @click="removeImage(box)">
              <i class="fas fa-trash-alt text-light d-flex p-1"></i>
            </a>
          </div>
        </transition-group>
      </draggable>
      <b-modal id="preview-img-modal">
        <img :src="previewingImg" alt="Preview Image" class="img" width="100%">
        <template v-slot:modal-footer="{ ok }">
          <b-button @click="ok()">
            OK
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<style>
  .box { height: 58px; }
  .ghost { opacity: 0.5; }
  .drag-handle, .remove-box-btn { position: absolute; border-radius: 100px; right: -1px; }
  .drag-handle { cursor: move !important; bottom: 1px; } 
  .remove-box-btn { top: -1px; }
  .drag-handle i, .remove-box-btn i { font-size: 10px; }
</style>

<script>
  import draggable from "vuedraggable"

  export default {
    components: { draggable },
    props: ['default', 'existing'],
    data() {
      return {
        imageBoxes: [
          { id: '1', imgId: null, img: null, existing: null, fixed: true, isRemoving: 0, },
          { id: '2', imgId: null, img: null, existing: null, fixed: true, isRemoving: 0, },
          { id: '3', imgId: null, img: null, existing: null, fixed: true, isRemoving: 0, },
          { id: '4', imgId: null, img: null, existing: null, fixed: true, isRemoving: 0, },
        ],
        isDragging: false,
        delayedDragging: false,
        selectedImage: '',
      }
    },
    props: ['default', 'existing'],
    watch: {
      isDragging(newValue) {
        if (newValue) {
          this.delayedDragging = true
          return
        }
        this.$nextTick(() => {
          this.delayedDragging = false
        })
      }
    },
    computed: {
      previewingImg() {
        return this.selectedImage ? this.selectedImage : this.default
      }
    },
    methods: {
      triggerUpload(box) {
        let ref = `uploadInput-${box.id}`
        this.$refs[ref][0].click()
      },
      onFileChange(e, box) {
        if (box.existing) box.existing = null
        let files = e.target.files || e.dataTransfer.files
        if (!files.length) return

        this.createImage(files[0], box)
      },
      createImage(file, box) {
        let image = new Image()
        let reader = new FileReader()
        let that = this

        reader.onload = (e) => {
          box.img = e.target.result
        }

        reader.readAsDataURL(file)
      },
      showImage(img) {
        this.selectedImage = img
        this.$bvModal.show('preview-img-modal')
      },
      removeImage (box) {
        if (box.existing) {
          box.existing = null
          box.isRemoving = 1
        } else {
          box.img = null
          box.isRemoving = 0
        }
      },
    },
    mounted() {
      if (this.existing) {
        this.existing.forEach(img => {
          let imgBox = this.imageBoxes[img.order]
          imgBox.imgId = img.id
          imgBox.existing = img.path + '?' + new Date().getTime()
        })
      }
    }
  }
</script>