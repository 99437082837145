<template>
  <a href
    :id="btnId"
    class="btn btn-outline-primary mt-2" 
    @click.prevent="addProductToCart({ product, qty }).then(showTooltip)"
    v-b-tooltip.manual.v-success
    :title="$t('Added to cart!')">
    <i class="fas fa-plus-square"></i> <span class="text">{{ $t('Add to cart') }}</span>
  </a>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    props: {
      product: {
        type: Object,
        required: true
      },
      qty: {
        type: Number,
        default: 1,
      }
    },
    computed: {
      btnId() {
        return `atc-btn-${this.product.id}`
      },
    },
    methods: {
      ...mapActions('cart', ['addProductToCart']),
      showTooltip() {
        this.$root.$emit('bv::show::tooltip', this.btnId)

        setTimeout(() => {
          this.$root.$emit('bv::hide::tooltip', this.btnId)
        }, 1800)
      }
    },
  }
</script>
