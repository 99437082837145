<template>
  <div>
    <section class="section-content padding-y">
    	<div class="container pt-3">
        <b-alert v-model="showShippingFeeUpdatedModal" variant="danger" dismissible>
          {{ $t('shippingCostUpdated') }}
        </b-alert>
    		<div class="row">
    			<main class="col-md-9 mx-auto">
            <div v-if="cartCount > 0">
      				<div class="card shadow">
      					<table class="table mb-0">
      						<tbody>
      							
      							<!-- Start cart item listing -->
                    <template v-for="product in cartProducts">
                      <cart-item :key="product.id" :product="product"></cart-item>
                    </template>
      							<!-- End cart item listing -->

      							<tr>
      								<td colspan="2">
      									<button class="btn btn-block btn-secondary" type="button" @click="$router.push({ name: 'home' })">
                      		<i class="fa fa-chevron-circle-left"></i> {{ $t('Back to shop') }}
                      	</button>
      								</td>
      								<td class="pt-3">
      									<p class="font-weight-bold text-right mb-0 d-flex justify-content-end align-items-center">
                          <span class="small font-weight-light mr-2">{{ $t('Subtotal') }}</span> {{ cartTotal | currency }}
                        </p>
      								</td>
      							</tr>
      						</tbody>
      					</table>
      				</div>
      				<div class="card mt-3 shadow">
      					<div class="card-body">
                  <b-alert show>
                    <dl class="dlist-align">
                      <dt>{{ $t('Shipping Fee') }}</dt>
                      <dd v-if="inputs.shippingFee !== null" class="text-right">{{ inputs.shippingFee | currency }}</dd>
                      <dd v-else class="text-muted text-right">({{ $t('Please select a state') }})</dd>
                    </dl>
                    <dl class="dlist-align" v-if="inputs.shippingFee && discounted">
                      <dt>{{ $t('Discount') }}</dt>
                      <dd class="text-right">-{{ inputs.shippingFee | currency }}</dd>
                    </dl>
                    <dl class="dlist-align">
                      <dt>{{ $t('Order Total') }}</dt>
                      <dd v-if="inputs.shippingFee !== null" class="text-right h5"><strong class="text-primary">{{ total | currency }}</strong></dd>
                      <dd v-else class="text-muted text-right">-</dd>
                    </dl>
                  </b-alert>
    							<hr> <!-- Divider -->
    							<form class="form">
                    <h5 class="font-weight-bold"><i class="fa fa-user-circle mr-2"></i> {{ $t('Personal Info') }}</h5>
                    <input type="text" class="form-control mt-3" id="customer-name" :placeholder="$t('Recipient Name') + '*'" required v-model="inputs.name">
                    <input type="text" class="form-control mt-3" id="customer-contact" :placeholder="$t('Mobile Number') + '*'" required v-model="inputs.mobile">
                    <input type="text" class="form-control mt-3" id="customer-address" :placeholder="$t('Address') + '*'" required v-model="inputs.address">
                    <b-form-select v-model="selectedState" :options="states" class="mt-3" @change="updateShippingFee">
                      <template v-slot:first>
                        <b-form-select-option :value="null" disabled>{{ $t('Please select a state') }}*</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="form-row">
                      <div class="col">
                        <input type="number" class="form-control mt-3" id="customer-postcode" :placeholder="$t('Postcode') + '*'" required v-model="inputs.postcode">
                      </div>
                      <div class="col">
                        <input type="text" class="form-control mt-3" id="customer-country" value="Malaysia" placeholder="国家 (Country)*" readonly v-model="inputs.country">
                      </div>
                    </div>
                    <textarea class="form-control mt-3" id="customer-remark" rows="3" :placeholder="$t('Notes')" v-model="inputs.remark"></textarea>
                    
                    <hr> <!-- Divider -->
                    <p class="font-weight-bold"><i class="fa fa-address-card mr-2"></i> {{ $t('Additional Info') }}</p>

                    <small v-show="!isValidEmail" class="form-text text-danger mb-1">{{ $t('Please enter a valid email address') }}</small>
                    <input type="email" class="form-control" id="customer-email" :placeholder="$t('Email Address')" v-model="inputs.email" aria-describedby="email-help">
                    <small id="email-help" class="form-text text-muted px-2">
                      <i class="fa fa-info-circle mr-1"></i> {{ $t('emailInfo') }}
                    </small>

                    <input type="text" id="customer-whatsapp" class="form-control mt-3" aria-describedby="whatsapp-helpblock" :placeholder="$t('Mobile Number') + ' (WhatsApp)'" v-model="inputs.whatsapp">
                    <small id="whatsapp-helpblock" class="form-text text-muted mt-2 d-block px-2">
                      <i class="fa fa-info-circle mr-1"></i> {{ $t('whatsappInfo') }}
                    </small>
                    <div class="alert alert-warning small mt-3 border border-warning">
                      {{ $t('additionalInfoWarning') }}
                    </div>
                  </form>
                  <hr> <!-- Divider -->
                  <b-button variant="success" block :disabled="isSubmitBtnDisabled" @click="showSubmitConfirmation">
                    <span>{{ $t('Submit order') }} ({{ total | currency }}) <i class="fas fa-caret-right ml-2"></i></span>
                  </b-button>
                  <p class="mb-0 small text-muted text-center mt-2">
                    * {{ $t('submitOrderRemark1') }}<br>{{ $t('submitOrderRemark2') }}
                  </p>
      					</div>
      				</div>
            </div>
            <div v-else class="jumbotron mt-3">
              <h1 class="display-5 text-primary">{{ $t('emptyCartTitle') }}</h1>
              <p class="lead">{{ $t('emptyCartMsg') }}</p>
              <hr class="my-4">
              <router-link :to="{ name: 'home' }">{{ $t('View products') }}</router-link>
            </div>
    			</main>
    		</div>
    	</div>	
    </section>
    <section class="section-name border-top padding-y">
      <div class="container">
        <h6 class="font-weight-bold">{{ $t('Payment & Refund Policy') }} <span class="small text-danger">{{ $t('Notice') }}</span></h6>
        <ul class="text-muted small mb-0">
          <li>{{ $t('paymentPolicy1') }}</li>
          <li>{{ $t('paymentPolicy2') }}</li>
          <li>{{ $t('paymentPolicy3') }}</li>
          <li>{{ $t('paymentPolicy4') }}</li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
  import CartItem from '../components/CartItem.vue'
	import { mapState, mapGetters } from 'vuex'
  import { Trans } from '../plugins/translation'

	export default {
		name: 'cart-page',
    components: { CartItem },
    data() {
      return {
        isShippingDiscountable: false,
        minShippingDiscountable: 10,
        showShippingFeeUpdatedModal: false,
        isProcessing: false,
        isValidEmail: true,
        selectedState: null,
        inputs: {
          name: '',
          mobile: '',
          address: '',
          state: null,
          postcode: '',
          country: 'Malaysia',
          remark: '',
          email: '',
          whatsapp: '',
          shippingFee: null,
          taxingFee: 0,
          locale: Trans.currentLanguage,
          discount: 0,
        }
      }
    },
    watch: {
      'inputs.email': function (newValue, oldValue) {
        this.debouncedCheckEmailInput()
      }
    },
		computed: {
      ...mapState({
        settings: state => state.settings.all,
        states: state => state.settings.states,
      }),
			...mapGetters({ 
				cartCount: 'cart/cartCount',
				cartProducts: 'cart/cartProducts',
        cartTotal: 'cart/cartTotalPrice',
				cartTotalItem: 'cart/cartTotalItem',
        getSetting: 'settings/getSettingByKey',
			}),
      total() {
        if (this.discounted) {
          this.inputs.discount = this.inputs.shippingFee
          return this.cartTotal
        } else {
          this.inputs.discount = 0
          return this.cartTotal + this.inputs.shippingFee
        }
      },
      discounted() {
        return this.isShippingDiscountable && this.cartTotalItem >= this.minShippingDiscountable
      },
      isSubmitBtnDisabled() {
        return !this.isFormReady || this.isProcessing
      },
      isFormReady() {
        let inputs = this.inputs
        return (
          !!inputs.name &&
          !!inputs.mobile && 
          !!inputs.address && 
          !!inputs.state &&
          !!inputs.postcode && 
          !!inputs.country && 
          inputs.shippingFee !== null && 
          !!this.isValidEmail
        )
      },
		},
    methods: {
      checkEmailInput() {
        if (this.inputs.email) {
          const atLocation = this.inputs.email.lastIndexOf("@")
          const dotLocation = this.inputs.email.lastIndexOf(".")

          this.isValidEmail = (atLocation > 0 && dotLocation > atLocation + 1 && dotLocation < this.inputs.email.length - 1)
        } else {
          this.isValidEmail = true
        }
      },
      showSubmitConfirmation() {
        this.$bvModal.msgBoxConfirm(this.$t('submitOrderMsg'), {
          okVariant: 'primary',
          okTitle: this.$t('Confirm order'),
          cancelTitle: this.$t('Hold on'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }).then(isProceed => {
          if (isProceed) this.submitOrder()
        })
      },
      submitOrder() {
        this.showShippingFeeUpdatedModal = false
        this.isProcessing = true
        var parent = this

        // Check and ensure shipping fees are latest
        this.$store.dispatch('settings/getStatesWithFees').then(resp => {
          let matchedState = parent.states.filter(function(state) {
            return state.value.name == parent.inputs.state && state.value.fee == parent.inputs.shippingFee
          })

          // Shipping Fee has been updated, clear the state and show alert
          if (!matchedState.length) {
            parent.inputs.state = null
            parent.inputs.shippingFee = null
            parent.showShippingFeeUpdatedModal = true
            window.scrollTo(0,0)
            parent.isProcessing = false
          } else {
            parent.$store.dispatch('cart/submitOrder', parent.inputs).then(order => {
              parent.$router.push({ name: 'success', params: { order: order } })
            })
          }
        })
      },
      updateShippingFee(state) {
        this.inputs.state = state.name
        this.inputs.shippingFee = state.fee
      },
    },
    created() {
      this.$store.dispatch('settings/getStatesWithFees')
      this.$store.dispatch('settings/getAllSettings')
      this.debouncedCheckEmailInput = _.debounce(this.checkEmailInput, 500)
    },
    mounted() {
      this.isShippingDiscountable = this.getSetting('shipping_discount_enabled') ? parseInt(this.getSetting('shipping_discount_enabled').value) : false
      this.minShippingDiscountable = this.getSetting('shipping_discount_min') ? parseInt(this.getSetting('shipping_discount_min').value) : 10
    }
	}
</script>