import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
Vue.use(Router)

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(err => err)
}

const router = new Router({
    routes,
    mode: 'history',
    base: __dirname,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        return { x: 0, y: 0 }
    }
})

router.beforeEach((to, from, next) => {
    if (to.name == 'success' && !to.params.order) next({ name: 'home' })
    else next()
})

export default router