<template>
  <b-form-datepicker :id="id" :class="classes" :name="name" v-model="value" width="320px" v-bind="$attrs"></b-form-datepicker>
</template>

<script>
  export default {
    inheritAttrs: false,
    props: ['id', 'classes', 'name', 'selectedDate'],
    data() {
      const now = new Date()
      return {
        value: new Date(now.getFullYear(), now.getMonth(), now.getDate())
      }
    },
    mounted() {
      if (this.selectedDate) this.value = this.selectedDate
    }
  }
</script>