// initial state
const state = () => ({
	items: [],
})

// getters
const getters = {
	cartProducts: (state, getters, rootState) => {
		return state.items.map(({ id, quantity }, index) => {
			const product = rootState.products.all.find(product => product.id === id)
			return { ...product, quantity } 
		})
	},

	cartCount: (state, getters) => {
		return state.items.length
	},

	cartTotalPrice: (state, getters) => {
		return getters.cartProducts.reduce((total, product) => {
			return total + product.price * product.quantity
		}, 0)
	},

	cartTotalItem: (state, getters) => {
		return getters.cartProducts.reduce((qty, product) => {
			return qty + product.quantity
		}, 0)
	},

	cartSingleItemTotalPrice: (state, getters) => (id) => {
		let cartItem = getters.cartProducts.find(item => item.id == id)
		return cartItem ? (cartItem.price * cartItem.quantity) : 0
	}
}

// actions
const actions = {

	// Ensure all the items in the customer cart are still valid (not deleted by the Admin)
	verifyItems ({ state, rootState, commit }) {
		state.items.forEach(({ id, quantity }, index) => {
			// Remove item from cart if item don't have ID or don't have matching product ID
			if (!id) {
				commit('deleteItemFromCart', index)
			} else {
				const product = rootState.products.all.find(product => product.id === id)
				if (!product) {
					commit('deleteItemFromCart', index)
				}
			}
		})
	},

	addProductToCart ({ state, commit }, { product, qty }) {
		const cartItem = state.items.find(item => item.id === product.id)
		if (!cartItem) {
			commit('pushProductToCart', {
				id: product.id,
				quantity: qty
			})
		} else {
			commit('incrementItemQuantity', {
				id: cartItem.id,
				quantity: qty
			})
		}
	},

	removeProductFromCart ({ state, commit }, product) {
		const cartItem = state.items.find(item => item.id === product.id)
		if (cartItem) {
			commit('deleteProductFromCart', product.id)
		}
	},

	decreaseProductQuantity ({ state, commit }, { product, qty }) {
		const cartItem = state.items.find(item => item.id === product.id)
		if (cartItem) {
			commit('decrementItemQuantity', {
				id: product.id,
				quantity: qty
			})
		}
	},

	updateProductQuantity ({ state, commit }, { product, qty}) {
		const cartItem = state.items.find(item => item.id === product.id)
		if (cartItem) {
			commit('setItemQuantity', {
				id: product.id,
				quantity: qty
			})
		}
	},

	submitOrder ({ state, getters, commit }, customer) {
		return new Promise((resolve, reject) => {
			axios.post('/api/v1/order/create', {
				'products': getters.cartProducts, 
				'customer': customer
			}).then(resp => {
				if (resp.data.order.success) {
					commit('setCartItems', { items: [] }) /** empty cart **/
					resolve(resp.data.order)
				}
			}).catch(err => {
				reject(err)
			})
		})
	}
}

// mutations
const mutations = {
	setCartItems (state, { items }) {
		state.items = items
	},

	pushProductToCart (state, product) {
		state.items.push({
			id: product.id,
			quantity: product.quantity
		})
	},

	// Remove item from cart using Product ID
	deleteProductFromCart (state, productId) {
		let index = state.items.findIndex(item => item.id === productId)
		state.items.splice(index, 1)
	},

	// Remove item from cart using index
	deleteItemFromCart (state, index) {
		state.items.splice(index, 1)
	},

	incrementItemQuantity (state, product) {
		const cartItem = state.items.find(item => item.id === product.id)
		cartItem.quantity += product.quantity
	},

	decrementItemQuantity (state, product) {
		const cartItem = state.items.find(item => item.id === product.id)
		cartItem.quantity -= product.quantity
		if (cartItem.quantity <= 0) cartItem.quantity = 1
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}