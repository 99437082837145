<template>
  <section class="section-content padding-y">
    <div class="container pt-3">
      <div class="row">
        <main class="col-md-9 mx-auto">
          <h1 class="text-center font-weight-bold mb-3">{{ $t('Order Tracking') }}</h1>
          <div class="card text-center shadow">
            <div class="card-body">
              <p class="m-0 small text-muted">{{ $t('Your order code') }}</p>
              <h1 class="text-primary font-weight-bold underline"><u>{{ order.code }}</u></h1>
              <h5 class="text-center mb-3 mt-2">
                <b-badge pill :variant="order.statusColor" class="px-3 py-2">{{ $t(order.statusSlug) }}</b-badge>
              </h5>
              <div v-if="order.statusSlug == 'pending'">
                <p class="alert alert-warning">
                  {{ $t('orderPendingPaymentMsg') }}
                  <br>{{ $t('orderPendingPaymentMsg2') }}
                </p>
                <table class="table table-bordered mb-0">
                  <thead>
                    <tr class="bg-primary text-light">
                      <th scope="col">{{ $t('Order Total') }}</th>
                      <th scope="col">{{ order.total | currency }}</th>
                    </tr>
                  </thead>
                  <tbody class="bg-light">
                    <tr>
                      <td>{{ $t('Bank') }}</td>
                      <td>{{ clientBankBrand }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Account Name') }}</td>
                      <td>{{ clientBankName }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Account Number') }}</td>
                      <td>{{ clientBankAccount }}</td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="clientTngQRCode" class="mt-4">
                  <p>{{ $t('Or make payment using Touch \'n Go eWallet') }}</p>
                  <h5 v-if="clientTngName" v-text="clientTngName" class="font-weight-bold"></h5>
                  <b-img-lazy :src="'/' + clientTngQRCode" rounded="top" style="max-width: 300px;"></b-img-lazy>
                </div>
              </div>
              <div v-else-if="order.statusSlug == 'processing'">
                <p class="alert alert-info">{{ $t('orderProcessingMsg') }}</p>
              </div>
              <div v-else-if="order.statusSlug == 'shipped' && order.tracking">
                <div class="alert alert-success border">
                  <p>
                    {{ $t('orderShippedMsg1') }}<br>{{ $t('orderShippedMsg2') }}
                  </p>
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td class="border border-secondary">{{ $t('Delivery company') }}</td>
                        <td class="border border-secondary">{{ order.tracking.courierName }}</td>
                      </tr>
                      <tr>
                        <td class="border border-secondary">{{ $t('Order tracking number') }}</td>
                        <td class="border border-secondary">{{ order.tracking.trackingNumber }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="text-center">
                    <a :href="order.tracking.trackingLink" target="_blank" class="btn btn-primary">{{ $t('Click me to track order status') }}</a>
                  </div>
                </div>
              </div>
              <div v-else-if="order.statusSlug == 'canceled'">
                <div class="alert alert-warning border">
                  <p>{{ $t('orderCancelledMsg') }}</p>
                </div>
              </div>
            </div>
          </div>

          <div v-if="order.statusSlug !== 'canceled'">
            <div class="card text-center shadow mt-4">
              <div class="card-body">
                <table class="table table-bordered mb-0">
                  <thead>
                    <tr class="bg-dark text-light">
                      <th width="40%" class="text-left">{{ $t('Product name') }}</th>
                      <th class="text-center">{{ $t('Quantity') }}</th>
                      <th class="text-right">{{ $t('Product subtotal') }}</th>
                    </tr>
                  </thead>
                  <tbody class="bg-light">
                    <tr v-for="item in order.items" :key="item.id">
                      <td class="text-left align-middle">
                        <p class="m-0">{{ item.name }}</p>
                        <p class="m-0 text-muted small">
                          {{ item.price | currency }} <span v-if="item.unit">/ {{ item.unit }}</span>
                        </p>
                      </td>
                      <td class="text-center align-middle">{{ item.quantity }}</td>
                      <td class="text-right align-middle">{{ item.total | currency }}</td>
                    </tr>
                    <tr>
                      <td colspan="2" class="pr-2 text-right">{{ $t('Subtotal') }}</td>
                      <td class="pr-2 text-right">{{ order.subtotal | currency }}</td>
                    </tr>
                    <tr>
                      <td colspan="2" class="pr-2 text-right">{{ $t('Shipping Fee') }}</td>
                      <td class="pr-2 text-right">{{ order.shippingPrice | currency }}</td>
                    </tr>
                    <tr v-if="order.discount">
                      <td colspan="2" class="pr-2 text-right">{{ $t('Discount') }}</td>
                      <td class="pr-2 text-right">-{{ order.discount | currency }}</td>
                    </tr>
                    <tr>
                      <td colspan="2" class="pr-2 text-right">{{ $t('Total') }}</td>
                      <td class="pr-2 text-right font-weight-bold">{{ order.total | currency }}</td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="order.remark" class="alert alert-secondary mt-2" role="alert">
                  <p class="alert-heading">{{ $t('Notes') }}</p>
                  <p class="mb-0">{{ order.remark }}</p>
                </div>
              </div>
              <div v-if="order.customer" class="card-body border-top">
                <table class="table table-bordered mb-0">
                  <tbody class="bg-light">
                    <tr>
                      <td>{{ $t('Recipient Name') }}</td>
                      <td>{{ order.customer.name }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Mobile Number') }}</td>
                      <td>{{ order.customer.mobile || '-' }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Email Address') }}</td>
                      <td>{{ order.customer.email || '-' }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Address') }}</td>
                      <td>
                        {{ order.customer.address }} <br>
                        {{ order.customer.postcode }} {{ order.customer.state }}, {{ order.customer.country }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div v-if="!order.isPay" class="card shadow mt-4">
              <div class="card-header font-weight-bold">
                <i class="fas fa-money-bill-wave mr-2 text-success"></i> {{ $t('uploadReceiptHeader') }}
              </div>
              <div v-if="isReceiptPending" class="card-body">
                <div class="alert alert-success border mb-0">
                  <p>{{ $t('uploadReceiptSuccessMsg') }}</p>
                </div>
              </div>
              <div v-else class="card-body">
                <p class="small">{{ $t('uploadReceiptDesc') }}</p>
                <b-form-file 
                  v-model="receiptFile"
                  ref="receiptFileInput"
                  class="mb-2"
                  @change="handleReceiptUpload()"
                  :browse-text="$t('Select file')"
                  accept="image/jpeg, image/png, image/bmp, image/jpg, image/gif, image/svg, image/webp"
                ></b-form-file>
                <p class="small text-muted">{{ $t('uploadReceiptRemark') }}</p>
                <b-button variant="success" block :disabled="isSubmitBtnDisabled" @click="showSubmitConfirmation" class="text-center">
                  <span>{{ $t('Upload receipt') }}</span>
                </b-button>
              </div>
            </div>
            <!-- ---------- -->
            <p class="text-center text-danger my-5">
              <i class="fa fa-info-circle mr-2"></i>{{ $t('orderTrackingError') }}
            </p>
          </div>
        </main>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'order-detail-page',
    data() {
      return {
        order: {},
        receiptFile: null,
        isProcessing: false,
        isReceiptPending: false,
      }
    },
    computed: {
      isSubmitBtnDisabled() { return !this.receiptFile || this.isProcessing },
      clientBankBrand() { return this.getSetting('bank_brand') ? this.getSetting('bank_brand').value : this.$t('Error, please contact us') },
      clientBankName() { return this.getSetting('bank_name') ? this.getSetting('bank_name').value : this.$t('Error, please contact us') },
      clientBankAccount() { return this.getSetting('bank_account') ? this.getSetting('bank_account').value : this.$t('Error, please contact us') },
      clientWhatsappNo() { return this.getSetting('whatsapp_no') ? this.getSetting('whatsapp_no').value : this.$t('Error, please contact us') },
      clientTngName() { return this.getSetting('tng_name') ? this.getSetting('tng_name').value : null },
      clientTngQRCode() { return this.getSetting('tng_qrcode') ? this.getSetting('tng_qrcode').value : null },
      ...mapGetters({ 
        getSetting: 'settings/getSettingByKey',
      }),
    },
    methods: {
      showSubmitConfirmation() {
        this.$bvModal.msgBoxConfirm(this.$t('uploadReceiptConfirm'), {
          okVariant: 'primary',
          okTitle: this.$t('Confirm upload'),
          cancelTitle: this.$t('Cancel'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }).then(isProceed => {
          if (isProceed) this.submitReceipt()
        })
      },
      submitReceipt() {
        this.isProcessing = true

        let formData = new FormData()
        formData.append('order_code', this.order.code)
        formData.append('receipt', this.receiptFile)

        axios.post('/api/v1/order/upload-receipt',
          formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        ).then(resp => {
          this.$bvToast.toast(this.$t('uploadReceiptSuccessMsg'), {
            title: this.$t('uploadReceiptSuccessTitle'),
            toaster: 'b-toaster-top-center',
            variant: 'success',
            solid: true
          })
          this.isProcessing = false
          this.isReceiptPending = true
        }).catch(err => {
          this.$bvToast.toast(this.$t('uploadReceiptFailMsg'), {
            title: this.$t('uploadReceiptFailTitle'),
            toaster: 'b-toaster-top-center',
            variant: 'danger',
            solid: true
          })
          this.isProcessing = false
          this.receiptFile = null
        })
      },
      handleReceiptUpload() {
        this.receiptFile = this.$refs.receiptFileInput.files[0]
      }
    },
    created() {
      if (this.$route.params.order) {
        this.order = this.$route.params.order
        if (this.order.receipt) this.isReceiptPending = true
      } else {
        const code = this.$route.params.code

        axios.post('/api/v1/order/get', {
          'order_code': code
        }).then(resp => {
          if (resp.data.order) {
            this.order = resp.data.order
            if (this.order.receipt) this.isReceiptPending = true
          } else {
            this.$router.push({ name: 'order' })
          }
        }).catch(err => {
          this.$router.push({ name: 'order' })
        })
      }
      
    }
  }
</script>