<template>
  <tr class="align-middle">
    <td>
      <img :src="item.product.thumbnail ? item.product.thumbnail : '/images/product-default.png'" :alt="item.name" class="mr-2 rounded" width="45px">
      <span>{{ item.name }}</span>
      <input v-if="item.id" type="hidden" :name="idInputName" :value="item.id">
      <input type="hidden" :name="productIdInputName" :value="item.product.id">
    </td>
    <td class="align-middle">
      <p class="m-0">
        {{ item.price | currency }}
        <template v-if="item.unit">
          <br> <small class="text-muted">/ {{ item.unit }}</small>  
        </template>
      </p>
    </td>
    <td class="align-middle text-center">
      <input type="number" v-model="item.qty" :name="qtyInputName" class="form-control text-right" style="min-width: 60px;" min="1">
    </td>
    <td class="text-right align-middle">{{ item.total | currency }}</td>
    <td class="text-right align-middle">
      <button class="btn btn-basic btn-sm text-danger" type="button" @click="showDeleteConfirmation">
        <i class="fa fa-times-circle"></i>
      </button>
    </td>
  </tr>
</template>

<script>

  export default {
    props: {
      item: {
        type: Object,
        required: true
      },
      index: {
        required: true
      }
    },
    watch: {
      'item.qty': function(newQty, oldQty) {
        this.debounceValidateInput(newQty)
      }
    },
    computed: {
      idInputName() { return `items[${this.index}][id]` },
      productIdInputName() { return `items[${this.index}][product_id]` },
      qtyInputName() { return `items[${this.index}][quantity]` },
    },
    methods: {
      showDeleteConfirmation() {
        this.$bvModal.msgBoxConfirm('Are you sure you want to remove the selected product from the order?', {
          okVariant: 'primary',
          okTitle: 'Confirm',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }).then(isProceed => {
          if (isProceed) this.$emit('destroying', this.index)
        })
      },
      validateInput(qty) {
        if (qty <= 0) this.item.qty = 1
        else this.item.total = qty * this.item.price
      }
    },
    created() {
      this.debounceValidateInput = _.debounce(this.validateInput, 500)
    },
  }
</script>
