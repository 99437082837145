import { Trans } from '../plugins/translation'

function load (page) {
    return () => import(/* webpackChunkName: "[request]" */ `../views/${page}.vue`)
}

export default [
    {
        // Redirect user to supported lang version.
        path: '/',
        redirect: to => {
            return Trans.getUserSupportedLang()
        }
    },
    {
      path: '/:lang',
      component: {
          template: '<router-view></router-view>'
        },
      beforeEnter: Trans.routeMiddleware,
      children: [
        {
            path: '',
            component: load('Homepage'),
        },
        {
            path: 'home',
            name: 'home',
            component: load('Homepage'),
        },
        {
            path: 'home/:filter',
            name: 'home-filter',
            component: load('Homepage'),
            props: true,
        },
        {
            path: 'cart',
            name: 'cart',
            component: load('CartPage'),
        },
        {
            path: 'product/:id',
            name: 'product-detail',
            component: load('ProductDetailPage'),
            props: true,
        },
        {
            path: 'success',
            name: 'success',
            component: load('SuccessPage'),
            props: true,
        },
        {
            path: 'order',
            name: 'order',
            component: load('OrderTrackingPage'),
        },
        {
            path: 'order/:code',
            name: 'order-detail',
            component: load('OrderDetailPage'),
            props: true,
        },
        {
            path: 'terms',
            name: 'terms',
            component: load('TermsPage'),
        },
        {
            path: 'privacy',
            name: 'privacy',
            component: load('PrivacyPage'),
        },
        {
            path: '*',
            redirect: 'home',
        },
      ]
    },
    {
        // Redirect user to supported lang version.
        path: '*',
        redirect: to => {
          return Trans.getUserSupportedLang()
        }
    }
  ]