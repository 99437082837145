<template>
  <div class="widget-header ml-2">
    <b-dropdown id="language-switcher" right size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
      <template v-slot:button-content>
        <div class="text-primary badge badge-light icon">
          {{ currentLanguage.label }}
        </div>
      </template>
      <b-dropdown-item
        v-for="lang in supportedLanguages"
        :key="lang.name"
        @click="changeLanguage(lang.name)"
        class="text-right"
      >
        {{ lang.label }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import { Trans } from '../plugins/translation'
export default {
  computed: {
    supportedLanguages () {
      return Trans.supportedLanguagesWithCodes
    },
    currentLanguage () {
      return this.supportedLanguages.find(lang => lang.name === Trans.currentLanguage);
    }
  },
  methods: {
    changeLanguage (lang) {
      const to = this.$router.resolve({ params: { lang } })
      return Trans.changeLanguage(lang).then(() => {
        this.$router.push(to.location)
      })
    }
  }
}
</script>

<style lang="scss">
#language-switcher {
  .dropdown-toggle { padding: 0; }
  .dropdown-menu { min-width: 4rem; }
  .badge { padding: 10.5px; font-size: 14px; }
}
</style>