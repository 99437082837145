<template>
  <div class="widget-header">
    <router-link :to="$i18nRoute({ name: 'cart' })" class="icontext">
      <div class="text text-right mr-2">
        <small class="text-muted">{{ $t('Cart') }}</small> 
        <div class="d-block badge badge-warning badge-pill mt-1 py-1 px-2" v-if="hasItemInCart">
          <small class="font-weight-light">{{ $t('Total') }}:</small> {{ total | currency }}
        </div>
      </div>
      <div class="badge badge-light p-2 icon">
        <i class="text-primary fa fa-2x fa-shopping-basket"></i>
        <span v-if="hasItemInCart" class="notify bg-primary" v-text="count"></span>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    hasItemInCart() {
      return this.count > 0
    },
    ...mapGetters({
      count: 'cart/cartCount',
      total: 'cart/cartTotalPrice',
    })
  },
}
</script>