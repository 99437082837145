<template>
  <div class="table-responsive">
    <table class="table table-bordered table-striped mb-0" cellspacing="0">
      <thead>
        <tr>
          <th width="60%">Item</th>
          <th>Cost</th>
          <th class="text-center">Qty</th>
          <th class="text-right">Total</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in order.items">
          <order-product-item :item="item" :index="index" :key="item.id" @destroying="removeItem"></order-product-item>
        </template>
        <tr class="align-middle">
          <td colspan="4">
            <b-button v-b-modal.product-select-modal variant="secondary" size="sm">+ Add Product</b-button>
          </td>
        </tr>
        <tr class="bg-white">
          <td colspan="3" class="text-right align-middle">Subtotal</td>
          <td class="text-right">
            <div class="input-group" style="min-width: 130px;">
              <div class="input-group-prepend">
                <div class="input-group-text">RM</div>
              </div>
              <input type="number" v-model="orderSubtotalCurrency" class="form-control text-right" placeholder="0.00" disabled>
            </div>
          </td>
        </tr>
        <tr class="bg-white">
          <td colspan="3" class="text-right align-middle">Shipping Fee</td>
          <td>
            <div class="input-group" style="min-width: 130px;">
              <div class="input-group-prepend">
                <div class="input-group-text">RM</div>
              </div>
              <input type="number" name="shipping_price" step="0.01" min="0.00" v-model="order.shipping_price" class="form-control text-right" placeholder="0.00" required>
            </div>
          </td>
        </tr>
        <tr class="bg-white">
          <td colspan="3" class="text-right font-weight-bold align-middle">Total</td>
          <td>
            <div class="input-group" style="min-width: 130px;">
              <div class="input-group-prepend">
                <div class="input-group-text">RM</div>
              </div>
              <input type="number" v-model="orderTotal" class="form-control text-right font-weight-bold" placeholder="0.00" disabled>
            </div>
          </td>
        </tr>
      </tbody>

    </table>
    <b-modal id="product-select-modal" title="Add product" centered no-close-on-backdrop @cancel="clearInput" @ok="addProductToOrder">
      <p class="mb-0">Search product using Title or SKU</p>
      <small class="text-muted">Please enter 3 or more characters to start searching...</small>
      <div v-if="selectedProduct" class="input-group">
        <input type="text" readonly class="form-control" :value="selectedProduct.title">
        <div class="input-group-append">
          <button class="btn btn-danger" type="button" @click="selectedProduct = null"><i class="fa fa-times-circle"></i></button>
        </div>
      </div>
      <input v-else ref="dropdowninput" v-model.trim="searchInputValue" class="form-control dropdown-input" type="text" placeholder="Search Product" />
      <b-dropdown id="product-select-dropdown" no-caret block class="scrollable-dropdown" menu-class="w-100" :menu-class="{ show: isLoaded && searchInputValue.length >= 3 }">
        <b-dropdown-item href="#" @click="selectProduct(product)" v-for="product in filteredProducts" :key="product.id">
          {{ product.title }}
        </b-dropdown-item>
        <b-dropdown-item v-show="filteredProducts.length <= 0" href="#" disabled>No matches found</b-dropdown-item>
      </b-dropdown>
      <template v-slot:modal-footer="{ cancel, ok }">
        <b-button variant="secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="primary" @click="ok()" :disabled="!selectedProduct">
          Add To Order
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<style>
  #product-select-dropdown button.dropdown-toggle { display: none; }
  .scrollable-dropdown .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
  }
</style>

<script>
  import axios from 'axios'
  import OrderProductItem from '../components/OrderProductItem.vue'

  export default {
    components: { OrderProductItem },
    props: {
      rawOrder: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        selectedProduct: null,
        isLoaded: false,
        searchInputValue: '',
        order: this.rawOrder,
        products: [],
      }
    },
    computed: {
      orderSubtotal() {
        return _.sumBy(this.order.items, (item) => { return Number(item.total) })
      },
      orderSubtotalCurrency() {
        return parseFloat(this.orderSubtotal).toFixed(2)
      },
      orderTotal() {
        return parseFloat(this.orderSubtotal + Number(this.order.shipping_price)).toFixed(2)
      },
      filteredProducts() {
        if (this.searchInputValue && this.searchInputValue.length >= 3) {
          let key = this.searchInputValue.toLowerCase()
          return this.products.filter(product => {
            return product.title.toLowerCase().includes(key) || (product.sku && product.sku.toLowerCase().includes(key))
          })
        } else {
          return this.products
        }
      }
    },
    methods: {
      removeItem(index) {
        this.order.items.splice(index, 1)
      },
      selectProduct(product) {
        this.selectedProduct = product
        this.searchInputValue = ''
      },
      clearInput() {
        this.selectedProduct = null
        this.searchInputValue = ''
      },
      addProductToOrder() {
        let item = {
          name: this.selectedProduct.title,
          order_id: this.order.id,
          price: this.selectedProduct.price,
          product: this.selectedProduct,
          product_id: this.selectedProduct.id,
          qty: 1,
          total: this.selectedProduct.price,
          unit: this.selectedProduct.unit,
        }
        this.order.items.push(item)
        this.clearInput()
      },
    },
    created() {
      axios.get('/api/v1/product/get').then(resp => {
        this.products = resp.data.products
        this.isLoaded = true
      })
    }
  }
</script>
