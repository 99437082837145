<template>
	<header class="section-header">
	  <section class="header-main border-bottom">
	    <div class="container">
	      <div class="row align-items-center">
	        <div class="col">
				<router-link :to="$i18nRoute({ name: 'home'})" class="brand-wrap">
					<img class="logo" src="/images/logo.png" alt="Layang Food">
				</router-link>
	        </div>
	        <div class="col">
	          <div class="widgets-wrap d-flex justify-content-end">
	            <header-cart/>
				<language-switcher/>
	          </div>
	        </div>
	      </div>
	    </div>
	  </section>
	</header>
</template>

<script>
	import HeaderCart from '../components/HeaderCart.vue'
	import LanguageSwitcher from '../components/LanguageSwitcher.vue'
	
	export default {
		components: { HeaderCart, LanguageSwitcher },
	}
</script>