import axios from 'axios'
import _ from 'lodash'

// initial state
const state = () => ({
  all: [],
  categories: []
})

// getters
const getters = {
  getProductById: (state) => (id) => {
    return state.all.find(product => product.id == id)
  },

  getFeaturedProducts: (state) => {
    return state.all.filter(product => product.isFeatured)
  },

  getHotProducts: (state) => {
    return state.all.filter(product => product.isHot)
  },

  getNewProducts: (state) => {
    return state.all.filter(product => product.isNew)
  },

  getDiscountedProducts: (state) => {
    return state.all.filter(product => product.originalPrice)
  },

  getCategoryProducts: (state) => (category) => {
    let result = _.filter(state.all, { categories: [{ slug: category }] })
    return result
  },
}

// actions
const actions = {
  getAllProducts ({ dispatch, commit }) {
    axios.get('/api/v1/product/get').then(resp => {
      commit('setProducts', resp.data.products)
      dispatch('cart/verifyItems', null, { root: true })
    })
  },

  getAllCategories ({ commit }) {
    axios.get('/api/v1/product/get_assigned_categories').then(resp => {
      commit('setCategories', resp.data.categories)
    })
  },
}

// mutations
const mutations = {
  setProducts (state, products) {
    state.all = products
  },

  setCategories (state, categories) {
    state.categories = categories
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}