<template>
	<div class="container pt-3">
		<div v-if="newsDesktopImg" class="row mb-5 d-none d-md-block">
			<div class="col-12 text-center">
				<b-img-lazy  rounded :src="newsDesktopImg" alt="News" fluid class="shadow"></b-img-lazy>
			</div>
		</div>
		<div v-if="newsMobileImg" class="row mb-4 d-md-none">
			<div class="col-12 text-center">
				<b-img-lazy rounded :src="newsMobileImg" alt="News" fluid class="shadow"></b-img-lazy>
			</div>
		</div>
		<div class="row mt-2 mb-3">
			<div class="col-12">
				<b-input-group class="mb-2 justify-content-end">
					<template v-slot:prepend>
      			<b-input-group-text><i class="fas fa-filter mr-2"></i>{{ $t('View') }}</b-input-group-text>
    			</template>
    			<b-form-input readonly class="bg-white" v-model="selectedFilter.name"></b-form-input>
    			<template v-slot:append>
	    			<b-dropdown right>
					    <b-dropdown-item v-for="filter in filtersWithProduct" @click="selectFilter(filter)" :key="filter.slug">
					    	{{ filter.name }}
					    </b-dropdown-item>
					    <b-dropdown-divider></b-dropdown-divider>
					    <b-dropdown-group :header="$t('categoryFilter')">
					      <b-dropdown-item v-for="category in categories" :key="category.id" @click="selectFilter(category)">
					      	{{ category.name }}
					      </b-dropdown-item>
					    </b-dropdown-group>
					  </b-dropdown>
    			</template>
			  </b-input-group>
			</div>
		</div>
		<div id="all-products-row" class="row">
			<!-- Start product listing -->
    	<template v-for="product in displayingProducts">
      	<div class="product col-6 col-md-4 col-lg-3" :class="product.id" :key="product.id">
		      <figure class="shadow card card-product-grid">
		        <div class="img-wrap">
	            <span v-if="product.isNew" class="badge badge-success p-2 shadow">
	              <i class="fa fa-box fa-lg text-light mb-1"></i> <br>
	              <small>{{ $t('New') }}</small>
	            </span>
	            <span v-if="product.isHot" class="best-buy badge badge-danger p-2 shadow">
	              <i class="fa fa-fire-alt fa-lg text-light mb-1"></i> <br>
	              <small>{{ $t('Hot') }}</small>
	            </span>
				<router-link :to="{ name: 'product-detail', params: { id: product.id }}">
					<b-img-lazy :src="product.thumbnail ? product.thumbnail : '/images/product-default.png'" :alt="product.title" rounded="top"></b-img-lazy>
				</router-link>
		        </div> <!-- img-wrap.// -->
		        <figcaption class="info-wrap">
		        	<router-link :to="{ name: 'product-detail', params: { id: product.id }}" class="title mb-1">
						<strong v-text="product.title"></strong>
		        	</router-link>
		          <p class="description text-muted">
		          	<small>{{ product.shortDescription }}</small>
		          </p>
		          <div class="product-grid-bottom">
			          <hr class="mt-3 mb-2">
			          <div class="price-wrap">
		              <div v-if="product.originalPrice">
		                <small class="text-success">
		                  <i class="fa fa-long-arrow-alt-down"></i> <del>RM{{ product.originalPrice }}</del>
		                  <span class="badge badge-success badge-label text-white font-weight-light ml-1">优惠中</span>
		                </small>
		              </div>
		            	<span class="price">RM{{ product.price }}</span>
		              <small v-if="product.unit" class="text-muted">/ {{ product.unit }}</small>
			          </div> <!-- price-wrap.// -->
			          <add-to-cart-btn :product="product"></add-to-cart-btn>
		          </div>
		        </figcaption>
		      </figure>
		    </div>
    	</template>
	   <!-- Ends product listing -->

		</div>
		<back-to-top/>
	</div>	
</template>

<script>
	import BackToTop from '../components/BackToTop.vue'
	import { mapState, mapGetters } from 'vuex'

	export default {
		name: 'homepage',
		components: { BackToTop },
		data() {
			return {
				filters: {
					all: { slug: 'all', name: this.$t('All products'), action: 'allProducts'},
					featured: { slug: 'featured', name: this.$t('Featured products'), action: 'featuredProducts' },
					hot: { slug: 'hot', name: this.$t('Hot products'), action: 'hotProducts' },
					new: { slug: 'new', name: this.$t('New products'), action: 'newProducts' },
					discounted: { slug: 'discounted', name: this.$t('Discounted products'), action: 'discountedProducts' },
				},
				selectedFilter: { slug: 'all', name: this.$t('All products'), action: 'allProducts'},
			}
		},
		computed: {
			...mapState({
				allProducts: state => state.products.all,
				categories: state => state.products.categories,
			}),
			...mapGetters({ 
				featuredProducts: 'products/getFeaturedProducts',
				hotProducts: 'products/getHotProducts',
				newProducts: 'products/getNewProducts',
				discountedProducts: 'products/getDiscountedProducts',
				categoryProducts: 'products/getCategoryProducts',
        		getSetting: 'settings/getSettingByKey',
			}),
			filtersWithProduct() {
				let parent = this
				return _.filter(this.filters, function(f) { 
					return parent.countProducts(f.action)
				})
			},
			newsDesktopImg() {
				return this.getSetting('news_img_desktop') ? '/' + this.getSetting('news_img_desktop').value : null
			},
			newsMobileImg() {
				return this.getSetting('news_img_mobile') ? '/' + this.getSetting('news_img_mobile').value : null
			},
			getCategoryProducts() {
				return this.categoryProducts(this.selectedFilter.slug)
			},
			displayingProducts() {
				let action = this.selectedFilter.action
				if (action) {
					return this[action]
				} else {
					return this.getCategoryProducts
				}
			}
		},
		methods: {
			selectFilter(filter) {
				if (this.selectedFilter.slug !== filter.slug){
					this.selectedFilter = filter
					if (filter.slug == "all") {
						this.$router.push({ name: 'home' })
					} else {
						this.$router.push({ name: 'home-filter', params: { filter: filter.slug } })
					}
				}
			},
			countProducts(action) {
				let items = this[action]
				return items.length ? true : false
			}
		},
		created() {
	    	this.$store.dispatch('products/getAllCategories')

			if (this.$route.params.filter) {
				let param = this.$route.params.filter
				
				if (this.filters[param]) {
					this.selectedFilter = this.filters[param]
				} else {
					this.selectedFilter = this.categories.find(category => category.slug == this.$route.params.filter)	
				}
			} else {
				this.selectedFilter = this.filters.all
			}
		}
	}
</script>