<template>
	<div>
		<section class="section-content bg-white padding-y">
		  <div class="container">
		    <div class="row">
		      <aside class="col-md-6">
		      	<a href @click.prevent="$router.go(-1)">
		      		<i class="fa fa-arrow-alt-circle-left mb-3"></i> {{ $t('Back') }}
		      	</a>
		        <div class="card border-0">
		          <article class="gallery-wrap">
		          	<b-carousel
		          		v-if="withImages"
		          		ref="productDetailCarousel"
						      v-model="slide"
						      :interval="0"
						      controls
						      img-width="1024"
						      img-height="480"
						      class="mb-2 shadow"
						    >
						    	<b-carousel-slide :img-src="product.thumbnail"></b-carousel-slide>
						      <b-carousel-slide v-for="image in product.images" :key="image.id" :img-src="image.path"></b-carousel-slide>
						    </b-carousel>
		            <div v-else class="img-big-wrap border shadow">
		              <div>
		              	<img :src="product.thumbnail ? product.thumbnail : '/images/product-default.png'" :alt="product.title" width="100%">
		              </div>
		            </div>
		            <div v-if="withImages" class="thumbs-wrap">
		            	<a href="#" @click.prevent="goToSlide(0)" class="item-thumb" :class="{ 'border-primary': slide == 0 }">
		            		<img :src="product.thumbnail">
		            	</a>
		            	<a href="#"
		            		v-for="(image, index) in product.images"
		            		@click.prevent="goToSlide(index + 1)" 
		            		class="item-thumb"
		            		:class="{ 'border-primary': slide == index + 1 }"
		            		:key="image.id"
		            	>
		            		<img :src="image.path">
		            	</a>
								</div>
		          </article>
		        </div>
		      </aside>
		      <main class="col-md-6">
		        <article class="product-info-aside">
		          <h2 class="title mt-3" v-text="product.title"></h2>
		          <div v-if="product.isNew || product.isBest" class="rating-wrap mt-1">
		            <small v-if="product.isNew" class="label-rating badge badge-success text-light p-2 font-weight-light">
		            	<i class="fa fa-box"></i> {{ $t('New') }}
		            </small>
		            <small v-if="product.isBest" class="label-rating badge badge-danger text-light p-2 font-weight-light">
		            	<i class="fa fa-fire"></i> {{ $t('Hot') }}
		            </small>
		          </div>
		          <div class="mt-3">
	              <div v-if="product.originalPrice">
	                <small class="text-success">
	                  <i class="fa fa-long-arrow-alt-down"></i> <del>RM{{ product.originalPrice }}</del>
	                  <span class="badge badge-light badge-label text-success">{{ $t('On sale') }}</span>
	                </small>
	              </div>
		            <var class="price h4">RM{{ product.price }}</var>
		            <span v-if="product.unit" class="text-muted">/ {{ product.unit }}</span>
		          </div>
		          <p class="text-muted">{{ product.shortDescription }}</p>
		          <div v-if="product.categories">
		          	<span v-for="category in product.categories" :key="category.id" class="badge badge-pill badge-secondary mr-2 px-2 py-1">
		          		<i class="fa fa-tag"></i> {{ category.name }}
		          	</span>
		          </div>

		          <div class="form-row mt-4">
		            <div class="form-group col-12 mb-2">
		              <div class="input-group">
		                <div class="input-group-prepend">
		                	<button class="btn btn-light" type="button" @click="decreaseQty(5)">-5</button>
		                  <button class="btn btn-light" type="button" @click="decreaseQty(1)">-</button>
		                </div>
		                <input type="text" class="form-control text-center" v-model="qty" readonly style="max-width: 80px;">
		                <div class="input-group-append">
		                  <button class="btn btn-light" type="button" @click="qty++">+</button>
		                  <button class="btn btn-light" type="button" @click="qty+=5">+5</button>
		                </div>
		              </div>
		            </div>
		            <div class="form-group col-12">
		            	<add-to-cart-btn :product="product" :qty="qty"></add-to-cart-btn>
		            </div>
		          </div>
		        </article>
		      </main>
		    </div>
		  </div>
		</section>
	  <section v-if="product.description" class="section-name padding-y bg">
	    <div class="container">
	      <div class="row">
	        <div class="col-md-8">
	          <h5 class="title-description text-muted">{{ $t('Product Description') }}</h5>
	          <p style="white-space: pre-wrap;" v-html="product.description">	</p>
	        </div>
	      </div>
	    </div>
	  </section>
	</div>
</template>

<script>
	import { mapState, mapGetters } from 'vuex'

	export default {
		name: 'product-detail-page',
		data() {
			return {
				qty: 1,
				slide: 0,
			}
		},
		computed: {
			...mapGetters({ getProductById: 'products/getProductById' }),
			product() {
      	return this.getProductById(this.$route.params.id)
    	},
    	withImages() {
    		return this.product.images.length > 0 && this.product.thumbnail
    	}
		},
		methods: {
			decreaseQty(qty) {
				this.qty -= qty
				if (this.qty <= 0) this.qty = 1
			},
			goToSlide(index) {
				this.$refs.productDetailCarousel.setSlide(index)
			}
		},
		created() {
    	if (!this.product) this.$router.push({ name: 'Home' }).catch(err => {})
    }
	}
</script>