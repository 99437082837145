import _ from 'lodash'
import Popper from 'popper.js'
import Vue from 'vue'

window.axios = require('axios')
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
// Vue.component('example-component', require('./components/ExampleCompon\ent.vue').default);

import App from './App.vue'
import router from './router'
import store from './store/'
import BootstrapVue from 'bootstrap-vue'

Vue.use(BootstrapVue)

import { i18n } from './plugins/i18n'
import { Trans } from './plugins/translation'

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
Vue.config.productionTip = false

// ====== Filters
Vue.filter('truncate', function (value, limit) {
  if (value.length > limit) value = value.substring(0, (limit - 3)) + '...'
  return value
})

Vue.filter('currency', function (value) {
    return 'RM' + parseFloat(value).toFixed(2)
});

const app = new Vue({
    el: '#app',
    router,
    store,
    i18n,
    render: h => h(App),
    created() {
      this.$store.dispatch('products/getAllProducts')
    }
});
