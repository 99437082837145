<template>
  <section class="section-content padding-y">
    <div class="container pt-3">
      <div class="row">
        <main class="col-md-9 mx-auto">
          <div class="card text-center shadow">
            <div class="card-body">
              <p class="m-0 small text-muted">{{ $t('orderSubmittedTitle') }}</p>
              <h1 class="text-primary font-weight-bold underline mb-3"><u>{{ order.code }}</u></h1>
              <p class="alert alert-warning">
                {{ $t('orderPendingPaymentMsg') }}
                <br>{{ $t('orderPendingPaymentMsg2') }}
              </p>
              <table class="table table-bordered mb-3">
                <thead>
                  <tr class="bg-primary text-light">
                    <th scope="col">{{ $t('Order Total') }}</th>
                    <th scope="col">{{ order.total | currency }}</th>
                  </tr>
                </thead>
                <tbody class="bg-light">
                  <tr>
                    <td>{{ $t('Bank') }}</td>
                    <td>{{ clientBankBrand }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('Account Name') }}</td>
                    <td>{{ clientBankName }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('Account Number') }}</td>
                    <td>{{ clientBankAccount }}</td>
                  </tr>
                </tbody>
              </table>
              <div v-if="clientTngQRCode" class="mt-4">
                <p>{{ $t('Or make payment using Touch \'n Go eWallet') }}</p>
                <h5 v-if="clientTngName" v-text="clientTngName" class="font-weight-bold"></h5>
                <b-img-lazy :src="'/' + clientTngQRCode" rounded="top" style="max-width: 300px;"></b-img-lazy>
              </div>
              <p class="text-center">
                <router-link :to="{ name: 'order-detail', params: { code: order.code }}">
                  {{ $t('Upload your receipt here') }}
                </router-link>
              </p> 
              <div class="small text-left alert alert-warning border" role="alert">
                <h5 class="alert-heading">
                  <i class="fas fa-exclamation-circle mr-1"></i> {{ $t('Notice') }}
                </h5>
                <p>{{ $t('orderTrackingReminder') }}</p>
              </div>
              <div v-if="order.customer.email" class="small text-left alert alert-success border" role="alert">
                <h5 class="alert-heading">
                  <i class="fas fa-paper-plane mr-2"></i> {{ $t('Sent to your email') }}
                </h5>
                <p>{{ $t('sentEmailMsg', [order.customer.email]) }}</p>
              </div>
              <div ref="messengerPlugin" class="fb-send-to-messenger mt-3 alert" :messenger_app_id="fbAppId" :page_id="fbPageId" :data-ref="fbRef"></div>
              <div v-if="isOptIn" class="small text-left alert alert-success border" role="alert">
                <h5 class="alert-heading">
                  <i class="fab fa-facebook-messenger mr-2"></i> {{ $t('Sent to Messenger') }}
                </h5>
                <p>{{ $t('sentToMessengerMsg') }}</p>
              </div>
              <a v-if="order.customer.whatsapp" :href="whatsappURL" target="_blank" class="btn btn-block mt-3 text-light font-weight-bold" style="background-color: #25D366;">
                <i class="fab fa-whatsapp mr-2 font-weight-bold"></i>{{ $t('Send to my WhatsApp') }}
              </a>
              <a href="/" class="btn btn-block btn-secondary mt-3">{{ $t('Back to shop') }}</a>
            </div>
          </div>
          <p class="text-center mt-4 text-muted">
            <i class="fas fa-lg fa-angle-double-down"></i>
          </p>
          <!-- ---------- -->
          <div role="tablist" class="mt-4">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.order-accordion-items variant="primary">
                  <i class="fa fa-shopping-bag mr-2"></i> {{ $t('Order Details') }}
                </b-button>
              </b-card-header>
              <b-collapse id="order-accordion-items" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <table class="table table-bordered mb-0">
                    <thead>
                      <tr class="bg-dark text-light">
                        <th width="40%" class="text-left">{{ $t('Product name') }}</th>
                      <th class="text-center">{{ $t('Quantity') }}</th>
                      <th class="text-right">{{ $t('Product subtotal') }}</th>
                      </tr>
                    </thead>
                    <tbody class="bg-light">
                      <tr v-for="item in order.items" :key="item.id">
                        <td>
                          <p class="m-0">{{ item.name }}</p>
                          <p class="m-0 text-muted small">
                            {{ item.price | currency }} <span v-if="item.unit">/ {{ item.unit }}</span>
                          </p>
                        </td>
                        <td class="text-center">{{ item.quantity }}</td>
                        <td class="text-right">{{ item.total | currency }}</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="pr-2 text-right">{{ $t('Subtotal') }}</td>
                        <td class="pr-2 text-right">{{ order.subtotal | currency }}</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="pr-2 text-right">{{ $t('Shipping Fee') }}</td>
                        <td class="pr-2 text-right">{{ order.shippingPrice | currency }}</td>
                      </tr>
                      <tr v-if="order.discount">
                        <td colspan="2" class="pr-2 text-right">{{ $t('Discount') }}</td>
                        <td class="pr-2 text-right">-{{ order.discount | currency }}</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="pr-2 text-right">{{ $t('Total') }}</td>
                        <td class="pr-2 text-right font-weight-bold">{{ order.total | currency }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-if="order.remark" class="alert alert-secondary mt-2" role="alert">
                    <p class="alert-heading">{{ $t('Notes') }}</p>
                    <p class="mb-0">{{ order.remark }}</p>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.order-accordion-customer variant="primary">
                  <i class="fa fa-user mr-2"></i> {{ $t('Customer Information') }}
                </b-button>
              </b-card-header>
              <b-collapse id="order-accordion-customer" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <table class="table table-bordered mb-0">
                    <tbody class="bg-light">
                      <tr>
                        <td>{{ $t('Recipient Name') }}</td>
                        <td>{{ order.customer.name }}</td>
                      </tr>
                      <tr v-if="withContactNumber">
                        <td>{{ $t('Mobile Number') }}</td>
                        <td>
                          <p v-if="order.customer.mobile" class="m-0">
                            <i class="fa fa-mobile mr-2"></i> {{ order.customer.mobile }}
                          </p>
                          <p v-if="order.customer.whatsapp" class="m-0">
                            <i class="fab fa-whatsapp mr-2"></i> {{ order.customer.whatsapp }}
                          </p>
                        </td>
                      </tr>
                      <tr v-if="order.customer.email">
                        <td>{{ $t('Email Address') }}</td>
                        <td>{{ order.customer.email }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('Address') }}</td>
                        <td>
                          <p class="m-0">{{ order.customer.address }}</p>
                          <p class="m-0">{{ order.customer.postcode }} {{ order.customer.state }}, {{ order.customer.country }}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <!-- ---------- -->
        </main>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { Trans } from '../plugins/translation'

  const FB_ORIGIN = process.env.MIX_LF_FB_ORIGIN
  const FB_PAGE_ID = process.env.MIX_LF_FB_PAGE_ID
  const FB_APP_ID = process.env.MIX_LF_FB_APP_ID

  export default {
    name: 'success-page',
    data() {
      return {
        order: {},
        fbOrigin: FB_ORIGIN,
        fbPageId: FB_PAGE_ID,
        fbAppId: FB_APP_ID,
        isOptIn: false,
      }
    },
    computed: {
      fbRef () {
        return Trans.currentLanguage + '/' + this.order.code
      },
      supportedLanguages () {
        return Trans.supportedLanguagesWithCodes
      },
      currentLanguage () {
        return this.supportedLanguages.find(lang => lang.name === Trans.currentLanguage);
      },
      withContactNumber() {
        return this.order.customer.mobile || this.order.customer.whatsapp
      },
      whatsappURL() {
        let path = window.location.origin + '/' + Trans.currentLanguage + '/order/' + this.order.code
        let link = encodeURIComponent(path)
        let msg = this.$t('sendToWhatsAppMsg', {
          code: this.order.code,
          total: this.order.total,
          link: link,
          clientBankBrand: this.clientBankBrand,
          clientBankName: this.clientBankName,
          clientBankAccount: this.clientBankAccount,
          clientWhatsappNo: this.clientWhatsappNo
        })
        
        return `https://wa.me/${this.order.customer.whatsapp}?text=${msg}`
      },
      clientBankBrand() { return this.getSetting('bank_brand') ? this.getSetting('bank_brand').value : this.$t('Error, please contact us') },
      clientBankName() { return this.getSetting('bank_name') ? this.getSetting('bank_name').value : this.$t('Error, please contact us') },
      clientBankAccount() { return this.getSetting('bank_account') ? this.getSetting('bank_account').value : this.$t('Error, please contact us') },
      clientWhatsappNo() { return this.getSetting('whatsapp_no') ? this.getSetting('whatsapp_no').value : this.$t('Error, please contact us') },
      clientTngName() { return this.getSetting('tng_name') ? this.getSetting('tng_name').value : null },
      clientTngQRCode() { return this.getSetting('tng_qrcode') ? this.getSetting('tng_qrcode').value : null },
      ...mapGetters({ 
        getSetting: 'settings/getSettingByKey',
      }),
    },
    methods: {
      confirmLeave() {
        return window.confirm(this.$t('confirmLeaveMsg'))
      }, 
      onBeforeUnload(e) {
        if (!this.confirmLeave()) {
          // Cancel the event
          e.preventDefault()
          // Chrome requires returnValue to be set
          e.returnValue = ''
        }  
      },
      async loadFacebookSDK(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src = `https://connect.facebook.net/${this.currentLanguage.fb}/sdk.js`
        fjs.parentNode.insertBefore(js, fjs)
      }
    },
    beforeRouteLeave (to, from, next) {
      if (this.$route.params.order) {
        if (this.confirmLeave()) next()
        else return false
      }

      next()
    },
    created() {
      let parent = this
      this.order = this.$route.params.order
      window.addEventListener('beforeunload', this.onBeforeUnload)

      this.$nextTick(() => {
        this.loadFacebookSDK(document, "script", "facebook-jssdk")
        window.fbAsyncInit = function() {
          FB.init({
            appId            : FB_APP_ID,
            autoLogAppEvents : true,
            xfbml            : true,
            cookie           : true,
            version          : 'v9.0'
          })

          // FB.getLoginStatus(function(response) { console.log(response) })

          FB.Event.subscribe('send_to_messenger', function(e) {
            console.log(e)
            if (e.event == 'opt_in') {
              parent.isOptIn = true
              parent.$refs.messengerPlugin.style.display = "none"
            }
          })
        }
      })
    }
  }
</script>