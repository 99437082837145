<template>
  <a href="#" @click.stop="setReceiptImage" v-b-modal.receiptPreviewModal>
    <i class="fas fa-receipt"></i>
  </a>
</template>

<script>
  export default {
    props: ['img'],
    methods: {
      setReceiptImage() {
        this.$parent.receipt.img = this.img
      }
    },
  }
</script>